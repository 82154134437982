<!-- eslint-disable vue/no-v-html -->
<template>
  <fragment>
    <v-col
      cols="12"
    >
      <div
        v-if="!isLoading"
        class="d-block"
      >
        <v-row>
          <v-col
            cols="12"
            md="12"
            class="ml-0 mr-0 pb-0 mb-0"
          >
            <v-row v-if="cotizadorHotels.ocupation.length > 1">
              <v-col
                cols="12"
                md="4"
                class="ml-0 mr-0 pb-0 mb-0"
              >
                {{ `${$t('lbl.room')} ${index + 1}` }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-select
                  v-model="ocupation.room_id"
                  :items="roomsHotel"
                  :label="$t('lbl.room')"
                  outlined
                  dense
                  item-text="name"
                  item-value="id"
                  hide-details
                  @change="changeRoom"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        {{ $t('lbl.room') }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item }">
                    <span v-text="item.name"></span>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title>
                        <!--{{ nameRoomWithPrice(item.name, item.id) }}-->
                        {{ item.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-select>
                <span class="mt-2">
                  <template v-if="tipoCama !== ''">
                    <v-icon
                      class="ml-0 mr-0 text-end text-right"
                    >
                      mdi-bed
                    </v-icon>
                    <span style="font-size: 12px">
                      {{ tipoCama }}
                    </span>
                    <br />
                  </template>

                  <v-icon
                    color="error"
                    small
                    class="ml-0 mr-0 text-end text-right"
                  >
                    mdi-timer
                  </v-icon>
                  <span style="font-size: 12px">
                    <template v-if="cupos < 10">
                      <span style="color: red">
                        {{ `${$tc('lbl.cantRoomRest1', cupos )} ${$tc('lbl.cantRoomRest2', cupos )}` }}
                      </span>
                    </template>
                    <template v-else>
                      <span>
                        {{ `${$t('lbl.cantRoomRest3' )} ${$tc('lbl.cantRoomRest2', cupos )}` }}
                      </span>
                    </template>
                  </span>
                </span>
                <template>
                  <br />
                  <br />
                  <v-icon
                    v-for="adul in ocupation.cantAdulEdad"
                    :key="Math.random() * (adul + 1)"
                    large
                    class="ml-0 mr-0 text-end text-right"
                  >
                    mdi-human-male
                  </v-icon>
                  <v-icon
                    v-for="teen in ocupation.cantTeenEdad"
                    :key="Math.random() * (teen + 1)"
                    class="ml-0 mr-0 mt-2 text-end text-right"
                  >
                    mdi-human-child
                  </v-icon>
                  <v-icon
                    v-for="child in ocupation.cantMnrEdad"
                    :key="Math.random() * (child + 1)"
                    class="ml-0 mr-0 mt-2 text-end text-right"
                  >
                    mdi-human-child
                  </v-icon>
                  <v-icon
                    v-for="inf in ocupation.cantInfEdad"
                    :key="Math.random() * (inf + 1)"
                    class="ml-0 mr-0 mt-2 text-end text-right"
                  >
                    mdi-human-child
                  </v-icon>
                </template>
              </v-col>

              <v-col
                cols="12"
                md="6"
                class="ml-0 mr-0 pb-0 mb-0"
              >
                <v-row>
                  <v-col cols="12">
                    <template v-if="regimenOcup.length > 0">
                      <b>{{ $t('lbl.regimen') }}</b>
                      <v-radio-group
                        v-if="regimenOcup.length > 1"
                        v-model="ocupation.plan"
                        class="px-2 py-1 mt-0 mb-0"
                        style="border:1px solid #dbdade; border-radius:5px;"
                        @change="changePlan"
                      >
                        <template v-for="(regimen, indeR) in regimenOcup">
                          <v-radio
                            :key="indeR"
                            :value="regimen.id"
                            class="pt-0 pb-0 mt-0 mb-0"
                            no-gutters
                          >
                            <template v-slot:label>
                              <v-row>
                                <v-col cols="9">
                                  <span style="font-size: 12px">{{ `${regimen.name} (${regimen.siglas})` }}</span>
                                </v-col>
                                <v-spacer></v-spacer>
                                <v-col
                                  cols="3"
                                  style="text-align: end"
                                >
                                  <span style="font-size: 12px">
                                    {{ `${regimen.price} ${currencySymbolFacturar}` }}
                                  </span>
                                </v-col>
                              </v-row>
                            </template>
                          </v-radio>
                          <v-divider
                            v-if="indeR !== regimenOcup.length - 1"
                            :key="Math.random() * (indeR + 1)"
                            class="mt-2 mb-2"
                          ></v-divider>
                        </template>
                      </v-radio-group>
                      <span v-else>
                        <br />
                        <v-icon
                          class="ml-0 mr-0 text-end text-right"
                        >
                          mdi-check
                        </v-icon>
                        {{ `${regimenOcup[0].name} (${regimenOcup[0].siglas})` }}
                      </span>
                    </template>
                  </v-col>
                  <v-col cols="12">
                    <template v-if="tarifaOcup.length > 0">
                      <v-row>
                        <v-col cols="12">
                          <b>{{ $t('lbl.policyCancele') }}</b>
                          <v-icon
                            style="cursor: pointer;"
                            class="ml-5"
                            @click="showModal()"
                          >
                            mdi-information-outline
                          </v-icon>
                          <v-radio-group
                            v-if="tarifaOcup.length > 1"
                            v-model="ocupation.codeTarifa"
                            class="px-2 py-1 mt-0 mb-0"
                            style="border:1px solid #dbdade; border-radius:5px;"
                            @change="changeTarifa"
                          >
                            <template v-for="(tarifaOc, indeRT) in tarifaOcup">
                              <v-radio
                                :key="indeRT"
                                :value="tarifaOc.code"
                                class="pt-0 pb-0 mt-0 mb-0"
                                no-gutters
                              >
                                <template v-slot:label>
                                  <v-row>
                                    <v-col cols="6">
                                      <v-row>
                                        <v-col cols="11">
                                          <span style="font-size: 12px">
                                            {{ `${tarifaOc.showName}` }}
                                            <template v-if="tarifaOc.slug === 'cancelacion-gratuita'">
                                              <br />
                                              <span style="font-size: 11px">
                                                <span v-if="tarifaOc.dates_hasta.length > 0">
                                                  {{ $t('lbl.hasta') }}
                                                  {{ tarifaOc.dates_hasta[0] }}
                                                </span>
                                                <span
                                                  v-else
                                                  style="color: red"
                                                >
                                                  {{ $t('lbl.withPenalidad') }}
                                                </span>
                                              </span>
                                            </template>
                                          </span>
                                        </v-col>
                                      </v-row>
                                    </v-col>
                                    <v-col cols="1">
                                      <v-tooltip
                                        top
                                        color="primary"
                                      >
                                        <template v-slot:activator="{ on, attrs }">
                                          <span
                                            v-bind="attrs"
                                            v-on="on"
                                          >
                                            <v-icon
                                              style="cursor: pointer;"
                                              class="ml-2"
                                            >
                                              mdi-information-outline
                                            </v-icon>
                                          </span>
                                        </template>
                                        <span>
                                          {{ $t('lbl.tarif') }}: {{ `${tarifaOc.name} (${tarifaOc.code})` }}
                                        </span>
                                      </v-tooltip>
                                    </v-col>

                                    <v-spacer></v-spacer>
                                    <v-col
                                      cols="3"
                                      style="text-align: end"
                                    >
                                      <span style="font-size: 12px">
                                        {{ `${tarifaOc.price} ${currencySymbolFacturar}` }}
                                      </span>
                                    </v-col>
                                  </v-row>
                                </template>
                              </v-radio>
                              <v-divider
                                v-if="indeRT !== tarifaOcup.length - 1"
                                :key="Math.random() * (indeRT + 1)"
                                class="mt-2 mb-2"
                              ></v-divider>
                            </template>
                          </v-radio-group>
                          <span v-else>
                            <br />
                            <template v-if="tarifaOcup[0].slug === 'cancelacion-gratuita'">
                              <v-icon
                                class="ml-0 mr-0 text-end text-right"
                              >
                                mdi-check
                              </v-icon>
                              {{ `${tarifaOcup[0].showName}` }}
                              <br />
                              <span style="font-size: 12px">
                                <span v-if="tarifaOcup[0].dates_hasta.length > 0">
                                  {{ $t('lbl.hasta') }}
                                  {{ tarifaOcup[0].dates_hasta[0] }}
                                </span>
                                <span
                                  v-else
                                  style="color: red"
                                >
                                  {{ $t('lbl.withPenalidad') }}
                                </span>
                              </span>
                            </template>
                            <template v-if="tarifaOcup[0].slug === 'no-reembolsable'">
                              <v-icon
                                class="ml-0 mr-0 text-end text-right"
                                color="error"
                              >
                                mdi-close
                              </v-icon>
                              {{ `${tarifaOcup[0].showName}` }}
                            </template>
                          </span>
                        </v-col>
                      </v-row>
                    </template>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="12"
            md="4"
            class="ml-0 mr-0 pt-0 mt-0"
          >
            <v-switch
              v-if="ocupation.adults + ocupation.childs > 1"
              v-model="showAllPaxsOcupation"
              :label="$t('lbl.roomingList')"
              hide-details
              class="pt-0 pb-0 mt-0 mb-0"
              @change="setShowAllOcupation"
            ></v-switch>
          </v-col>
          <v-col
            cols="12"
            md="8"
            class="ml-0 mr-0 pt-0 mt-0"
          >
          </v-col>

          <fragment v-if="showAll">
            <HotelRoomPax
              v-for="(pax, indexPax) in ocupation.paxs"
              :key="indexPax"
              :ocupation="ocupation"
              :pax="pax"
              :index="indexPax"
              :index-ocupation="index"
            />
          </fragment>
          <v-col
            cols="12"
            md="12"
          >
            <v-row>
              <v-col
                cols="12"
                md="4"
              >
                <span style="font-size: 14px">
                  {{ $t('lbl.observaciones') }}
                </span>

                <v-tooltip
                  top
                  color="primary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon
                        style="cursor: pointer;"
                        class="ml-2"
                        @click="addObservation"
                      >
                        mdi-plus
                      </v-icon>
                    </span>
                  </template>
                  <span>
                    {{ $t('btn.add') }}
                  </span>
                </v-tooltip>
              </v-col>

              <v-col cols="12">
                <v-row
                  v-for="(itemOb, indObj) in ocupation.observations"
                  :key="indObj"
                  no-gutters
                >
                  <v-col
                    cols="12"
                    md="10"
                  >
                    <span style="font-size: 12px">
                      {{ itemOb.observation }}
                    </span>
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                    class="text-right"
                  >
                    <v-tooltip
                      top
                      color="primary"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          fab
                          x-small
                          v-bind="attrs"
                          color="primary"
                          outlined
                          class="mr-2 mt-1"
                          @click="editObservation(indObj, itemOb)"
                          v-on="on"
                        >
                          <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('btn.edit') }}</span>
                    </v-tooltip>

                    <v-tooltip
                      top
                      color="error"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          fab
                          x-small
                          v-bind="attrs"
                          color="error"
                          outlined
                          class="mt-1"
                          @click="deleteObservation(indObj, itemOb)"
                          v-on="on"
                        >
                          <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('btn.delete') }}</span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="12">
                    <v-divider class="my-1"></v-divider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
      <div
        v-if="isLoading"
        class="d-block"
      >
        <v-skeleton-loader
          class="mx-auto"
          type="card"
        ></v-skeleton-loader>
      </div>
    </v-col>

    <v-col
      cols="12"
      justify="center"
    >
      <!--POLITICA DE PRIVACIDAD-->
      <v-dialog
        v-model="dialog"
        hide-overlay
        transition="dialog-bottom-transition"
        max-width="700"
      >
        <v-card>
          <v-toolbar
            dark
            color="primary"
          >
            <v-btn
              icon
              dark
              @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ $t('lbl.policyCancele') }}</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <template>
            <v-row class="my-2 mx-2">
              <v-col
                v-for="(tar, i) in tarifaOcup"
                :key="i"
                cols="12"
              >
                <v-list-item-title>
                  <b>{{ tar.showName }}</b>
                  <span style="font-size: 12px">
                    {{ `(${tar.name} ${tar.code})` }}
                  </span>
                </v-list-item-title>
                <template v-if="tar.slug === 'no-reembolsable'">
                  <span style="font-size: 12px">
                    {{ $t('lbl.titleOfert') }}
                  </span>
                </template>
                <template v-else>
                  <span
                    v-if="(i === 0 || i === 1) && tar.dates_hasta.length > 0"
                    style="font-size: 12px"
                  >
                    {{ $t('lbl.titleOfert2') }}
                    <br />
                  </span>
                  <span
                    class="mt-5"
                    style="font-size: 12px"
                  >
                    <template v-if="tar.dates_hasta.length > 0">
                      <b>{{ $t('lbl.beforeOf') }}</b> {{ tar.dates_hasta[0] }}
                      <br />
                      {{ $t('lbl.conditionsCancelation') }}
                      <br />
                      {{ $t('lbl.cancelaFree') }} {{ tar.dates_hasta[0] }}
                      <br />
                      <br />
                      <b>{{ $t('lbl.afterOf') }}</b> {{ tar.dates_hasta[0] }}
                    </template>
                    <template v-else>
                      <span style="color: red">{{ $t('lbl.withPenalidad') }}</span>
                      <br />
                      <b>{{ $t('lbl.afterOf') }}</b> {{ tar.dates_penalidad }}
                    </template>
                    <br />
                    {{ $t('lbl.conditionsCancelation') }}
                    <br />
                    {{ $t('lbl.cancelaPena1') }} {{ tar.dates_hasta.length > 0 ? tar.dates_hasta[0] : tar.dates_penalidad }} {{ $t('lbl.cancelaPena2') }}
                    <br />
                    <ul>
                      <li>
                        {{ `${regimenSelect.name} (${regimenSelect.siglas})` }}
                      </li>
                    </ul>
                    <br />
                    <b>{{ $t('lbl.penalidad') }}:</b>
                    <br />
                    <span
                      v-for="(data, iData) in tar.cancele_free_data"
                      :key="iData"
                    >
                      {{ penalidad(data) }}<br />
                    </span>

                    <br v-if="tar.conditions.length > 0" />
                    <template v-for="(condi, indC) in tar.conditions">
                      <span :key="indC">
                        <span>
                          <b>{{ condi.name }}</b>
                          <ul
                            v-for="(ccc, indCCC) in condi.conditions"
                            :key="indCCC"
                          >
                            <li v-html="ccc.description"></li>
                          </ul>
                        </span>
                      </span>
                    </template>
                  </span>
                </template>
                <v-divider
                  v-if="i !== tarifaOcup.length - 1"
                  class="mt-2"
                ></v-divider>
              </v-col>
            </v-row>
          </template>
        </v-card>
      </v-dialog>

      <!--ADD OBSERVATION-->
      <v-dialog
        v-model="dialogObservation"
        hide-overlay
        transition="dialog-bottom-transition"
        max-width="700"
      >
        <v-card max-height="400px">
          <v-toolbar
            dark
            color="primary"
          >
            <v-btn
              icon
              dark
              @click="dialogObservation = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ $t('lbl.observaciones') }}</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-row
              class="mt-3"
              no-gutters
            >
              <v-col
                cols="12"
              >
                <v-textarea
                  v-model="observation.observation"
                  :label="$t('lbl.observation')"
                  outlined
                  rows="4"
                  shaped
                ></v-textarea>
              </v-col>

              <v-col
                cols="12"
                md="3"
              >
                <v-switch
                  v-model="observation.proveedor"
                  label="Proveedor"
                  hide-details
                  class="pt-0 mt-0"
                ></v-switch>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-switch
                  v-model="observation.client"
                  label="Cliente"
                  hide-details
                  class="pt-0 mt-0"
                ></v-switch>
              </v-col>
              <v-col
                v-if="index === 0"
                cols="12"
                md="12"
              >
                <v-checkbox
                  v-model="checkAllService"
                  :label="$t('dashboard.observation.applyAll')"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-row class="mx-1">
              <v-col
                v-if="index === 0 && checkAllService"
                cols="12"
                md="2"
              >
                <!--<v-tooltip
                  top
                  color="primary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      color="primary"
                      outlined
                      :disabled="!observation.observation"
                      @click="addNewObservationAll"
                      v-on="on"
                    >
                      <v-icon>mdi-arrow-down-bold-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('dashboard.observation.applyAll') }}</span>
                </v-tooltip>-->
                <v-btn
                  color="primary"
                  outlined
                  :disabled="!observation.observation"
                  @click="addNewObservationAll()"
                >
                  <span>{{ $t('btn.save') }}</span>
                  <v-icon>{{ icons.mdiPlus }}</v-icon>
                </v-btn>
              </v-col>
              <v-col
                v-if="!checkAllService"
                cols="2"
              >
                <v-btn
                  color="primary"
                  :disabled="!observation.observation"
                  @click="addNewObservation()"
                >
                  <span>{{ $t('btn.save') }}</span>
                  <v-icon>{{ icons.mdiPlus }}</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!--EDIT OBSERVATION-->
      <v-dialog
        v-model="dialogObservationEdit"
        hide-overlay
        transition="dialog-bottom-transition"
        max-width="700"
      >
        <v-card max-height="400px">
          <v-toolbar
            dark
            color="primary"
          >
            <v-btn
              icon
              dark
              @click="dialogObservationEdit = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ $t('lbl.observaciones') }}</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-row class="mt-2">
              <v-col
                cols="12"
              >
                <v-textarea
                  v-model="observation.observation"
                  :label="$t('lbl.observation')"
                  outlined
                  rows="4"
                  shaped
                ></v-textarea>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-switch
                  v-model="observation.proveedor"
                  label="Proveedor"
                  hide-details
                  class="pt-0 mt-0"
                ></v-switch>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-switch
                  v-model="observation.client"
                  label="Cliente"
                  hide-details
                  class="pt-0 mt-0"
                ></v-switch>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-row class="mx-1">
              <v-col
                cols="2"
              >
                <v-btn
                  color="primary"
                  :disabled="!observation.observation"
                  @click="editNewObservation()"
                >
                  <span>{{ $t('btn.save') }}</span>
                  <v-icon>{{ icons.mdiPlus }}</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!--DELETE OBSERVATION-->
      <v-dialog
        v-model="dialogObservationDelete"
        hide-overlay
        transition="dialog-bottom-transition"
        max-width="700"
      >
        <v-card max-height="400px">
          <v-toolbar
            dark
            color="primary"
          >
            <v-btn
              icon
              dark
              @click="dialogObservationDelete = false; observation = { observation: null }"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ $t('lbl.observaciones') }}</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-row class="mt-2">
              <v-col
                cols="12"
              >
                <strong>{{ $t('dashboard.observation.delete') }}</strong>
              </v-col>
              <v-col
                cols="12"
              >
                <span style="font-size: 12px">
                  {{ observation.observation }}
                </span>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-row class="mx-1">
              <v-col
                cols="2"
              >
                <v-btn
                  color="error"
                  :disabled="!observation.observation"
                  @click="confirmDeleteObs()"
                >
                  <span>{{ $t('btn.delete') }}</span>
                  <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </fragment>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import {
  mdiCar,
  mdiBed,
  mdiCarDoor,
  mdiAccountOutline,
  mdiBagSuitcaseOutline,
  mdiStarOutline,
  mdiStar,
  mdiStarHalfFull,
  mdiDeleteOutline,
  mdiSquareEditOutline,
} from '@mdi/js'

import HotelRoomPax from './HotelRoomPax.vue'

export default {
  components: {
    HotelRoomPax,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    regimenes: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    ocupation: {
      type: Object,
    },
    // eslint-disable-next-line vue/prop-name-casing, vue/require-default-prop
    index: {
      type: Number,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      itemsOrigen: [],
      isLoading: true,
      loading: false,
      onlyIda: false,
      multiplesDestinos: false,
      image: null,
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      recogida: null,
      entrega: null,
      vrad: 0,
      value: null,
      value1: null,
      icons: {
        mdiCar,
        mdiBed,
        mdiCarDoor,
        mdiAccountOutline,
        mdiBagSuitcaseOutline,
        mdiStarOutline,
        mdiStar,
        mdiStarHalfFull,
        mdiDeleteOutline,
        mdiSquareEditOutline,
      },
      infoImportant: false,
      is_nivel_propietario: false,
      categCondicionesGenerales: [],
      condicionesGenerales: [],
      condicionesGenerales_id: [],
      checkbox1: true,
      services: [],
      roomsNomAll: [],
      roomsHotel: [],
      itemsRooms: [],
      searchRooms: null,
      showAll: true,
      showAllPaxsOcupation: false,
      regimenLocal: [],
      dialog: false,
      regimenPrices: [],
      markup: null,
      valueMarkup: null,
      descuento: null,
      valueDescuento: null,
      regimenOcup: [],
      tarifaOcup: [],
      cupos: 0,
      tiposCamas: [],
      user: {},
      dialogObservation: false,
      dialogObservationEdit: false,
      dialogObservationDelete: false,
      posObservationDelete: null,
      checkAllService: false,
      observation: {
        observation: null,
        proveedor: false,
        client: false,
      },
    }
  },
  computed: {
    ...mapState({
      showOcupationRoomHotel: state => state.app.showOcupationRoomHotel,
      cotizadorHotels: state => state.app.cotizadorHotels,
      cotizadorHotelsResult: state => state.app.cotizadorHotelsResult,
      cotizadorHotelsResultSelect: state => state.app.cotizadorHotelsResultSelect,
      cotizadorPaxCarSelect: state => state.app.cotizadorPaxCarSelect,
      cotizadorHotelsSelectMarkups: state => state.app.cotizadorHotelsSelectMarkups,
      cotizadorHotelsRomms: state => state.app.cotizadorHotelsRomms,
      tasaCambioContrate: state => state.app.tasaCambioContrate,
      tasaCambioFacturar: state => state.app.tasaCambioFacturar,
      currencySymbolFacturar: state => state.app.currencySymbolFacturar,
      currencyCodeFacturar: state => state.app.currencyCodeFacturar,
    }),
    cantAdults() {
      let cantA = 0
      this.cotizadorHotelsResultSelect.ocupation.forEach(element => {
        cantA += element.cantAdulEdad
      })

      return cantA
    },
    cantMenor() {
      let cantM = 0
      this.cotizadorHotelsResultSelect.ocupation.forEach(element => {
        cantM += element.cantMnrEdad
      })

      return cantM
    },
    regimenSelect() {
      let arr = ''

      if (this.regimenes.filter(e => e.id === this.ocupation.plan).length > 0) {
        // eslint-disable-next-line prefer-destructuring
        arr = this.regimenes.filter(e => e.id === this.ocupation.plan)[0]
      }

      return arr
    },

    /* regimenOcup() {
      const arr = []
      const ids = []

      if (this.regimenes.length > 0) {
        // ORDER PLANES
        const orderPlanes = []
        orderPlanes.push(this.regimenes.filter(e => e.siglas === 'EP')[0])
        orderPlanes.push(this.regimenes.filter(e => e.siglas === 'CP')[0])
        orderPlanes.push(this.regimenes.filter(e => e.siglas === 'MAP')[0])
        orderPlanes.push(this.regimenes.filter(e => e.siglas === 'AP')[0])
        orderPlanes.push(this.regimenes.filter(e => e.siglas === 'TI')[0])

        if (this.ocupation.room_id) {
          if (orderPlanes.length > 0) {
            orderPlanes.forEach(element => {
              this.cotizadorHotelsResultSelect.rooms.forEach(elementR => {
                elementR.days.forEach(elementDDD => {
                  elementDDD.group_net.forEach(elementGroup => {
                    elementGroup.day_by_day.forEach(dayBy => {
                      if (dayBy.room_id === this.ocupation.room_id) {
                        if (!ids.includes(element.id)) {
                          if (dayBy.plan === element.siglas) {
                            ids.push(element.id)

                            let priceAllCoti = 0
                            this.regimenPrices.forEach(elementPrice => {
                              if (elementPrice.plan_id === element.id
                              && elementPrice.room_id === this.ocupation.room_id
                              && elementPrice.code_tarifa === this.ocupation.codeTarifa) {
                                if (elementPrice.priceAll >= this.priceRoomPlanTarifa) {
                                  priceAllCoti = `+${elementPrice.priceAll - this.priceRoomPlanTarifa}`
                                } else {
                                  priceAllCoti = `-${this.priceRoomPlanTarifa - elementPrice.priceAll}`
                                }
                              }
                            })

                            arr.push({
                              ...element,
                              price: priceAllCoti,
                              color: 'red',
                            })
                          }
                        }
                      }
                    })
                  })
                })
              })
            })
          }
        }

        // eslint-disable-next-line prefer-destructuring, vue/no-side-effects-in-computed-properties
        this.ocupation.plan = arr[0].id
      }

      return arr
    }, */

    /* tarifaOcup() {
      const arr = []

      if (this.ocupation.room_id) {
        this.ocupation.tarifasSeason.forEach(element => {
          arr.push(element)
        })
      }

      return arr
    }, */
    precioInitial() {
      let priceTotal = 0

      this.cotizadorHotelsRomms.forEach(ocupa => {
        priceTotal += ocupa.price
      })

      return priceTotal
    },
    tipoCama() {
      let result = ''
      // eslint-disable-next-line no-plusplus
      for (let j = 0; j < this.tiposCamas.length; j++) {
        result += this.tiposCamas[j].name
        if (j < this.tiposCamas.length - 1) {
          result += ', '
        }
      }

      return result
    },
  },
  watch: {
    searchRooms(val) {
      if (val !== null && val.length > 1) {
        this.filterRooms(val.toLowerCase())
      }
    },
  },
  created() {
    this.profile()
    this.getRooms()

    // ASIGNAR EL PLAN
    if (this.cotizadorHotelsRomms[this.index]) {
      this.ocupation.plan = this.cotizadorHotelsRomms[this.index].plan
    }

    this.markup = localStorage.getItem('markup')
    this.valueMarkup = localStorage.getItem('valueMarkup')
    this.descuento = localStorage.getItem('descuento')
    this.valueDescuento = localStorage.getItem('valueDescuento')

    this.cotizadorHotelsResultSelect.rooms[this.index].days.forEach(elementDDD => {
      elementDDD.group_net.forEach(elementGroup => {
        let priceAll = (elementGroup.price_net / this.tasaCambioContrate) * this.tasaCambioFacturar
        if (this.markup) {
          // %
          if (this.valueMarkup === '%') {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            const precioWithMUP = (parseFloat(priceAll) * parseFloat(this.markup)) / 100
            // eslint-disable-next-line no-mixed-operators
            priceAll = parseFloat(priceAll) + precioWithMUP
          } // USD
          if (this.valueMarkup === this.currencyCodePVP) {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            priceAll = parseFloat(priceAll) + parseFloat(this.markup)
          }
        }
        if (this.descuento) {
          // %
          if (this.valueDescuento === '%') {
            priceAll -= ((priceAll * parseFloat(this.descuento)) / 100)
          } // USD
          if (this.valueDescuento === this.currencyCodePVP) {
            priceAll -= parseFloat(this.descuento)
          }
        }
        this.regimenPrices.push({
          code_tarifa: elementGroup.code_tarifa,
          plan: elementGroup.plan,
          plan_id: elementGroup.plan_id,
          price_net: elementGroup.price_net,
          priceAll,
          room_id: elementGroup.room_id,
        })
      })
    })

    this.initRegimenOcup()
    this.initTarifaOcup()
  },
  methods: {
    ...mapMutations([
      'updateOcupationHotel',
      'setCotizadorHotelSelectPriceTotal',
      'setShowRoomHotelCotizador',
      'setCotizadorHotelsRomms',
      'setOcupationHotelShowAll',
      'updatePlanOcupationHotel',
      'setCotizadorHotelsSelectMarkups',
      'updateOcupationHotelPriceNeto',
      'updateOcupationHotelOnlyRoomId',
      'updateOcupationHotelOnlyPlan',
      'updateOcupationHotelPricesFacturar',
      'updateOcupationHotelPricesChangePlan',
      'addOcupationObsevations',
      'addOcupationObsevationsAllRooms',
      'updateOcupationObsevations',
      'deleteOcupationObsevations',
    ]),
    initRegimenOcup() {
      this.regimenOcup = []
      const ids = []

      if (this.regimenes.length > 0) {
        // ORDER PLANES
        const orderPlanes = []
        orderPlanes.push(this.regimenes.filter(e => e.siglas === 'EP')[0])
        orderPlanes.push(this.regimenes.filter(e => e.siglas === 'CP')[0])
        orderPlanes.push(this.regimenes.filter(e => e.siglas === 'MAP')[0])
        orderPlanes.push(this.regimenes.filter(e => e.siglas === 'AP')[0])
        orderPlanes.push(this.regimenes.filter(e => e.siglas === 'TI')[0])

        if (this.ocupation.room_id) {
          if (orderPlanes.length > 0) {
            let priceRoomPlanTarifa = 0
            this.regimenPrices.forEach(element => {
              if (element.plan_id === this.ocupation.plan
              && element.room_id === this.ocupation.room_id
              && element.code_tarifa === this.ocupation.codeTarifa) {
                priceRoomPlanTarifa = element.priceAll
              }
            })

            orderPlanes.forEach(element => {
              this.cotizadorHotelsResultSelect.rooms.forEach(elementR => {
                elementR.days.forEach(elementDDD => {
                  elementDDD.group_net.forEach(elementGroup => {
                    elementGroup.day_by_day.forEach(dayBy => {
                      if (dayBy.room_id === this.ocupation.room_id) {
                        if (!ids.includes(element.id)) {
                          if (dayBy.plan === element.siglas) {
                            ids.push(element.id)

                            let priceAllCoti = 0
                            let symbol = '+'
                            this.regimenPrices.forEach(elementPrice => {
                              if (elementPrice.plan_id === element.id
                              && elementPrice.room_id === this.ocupation.room_id
                              && elementPrice.code_tarifa === this.ocupation.codeTarifa) {
                                if (elementPrice.priceAll >= priceRoomPlanTarifa) {
                                  priceAllCoti = `+${((elementPrice.priceAll - priceRoomPlanTarifa) / this.cotizadorHotelsResultSelect.days_search).toFixed(2)}`
                                } else {
                                  symbol = '-'
                                  priceAllCoti = `-${((priceRoomPlanTarifa - elementPrice.priceAll) / this.cotizadorHotelsResultSelect.days_search).toFixed(2)}`
                                }
                              }
                            })

                            this.regimenOcup.push({
                              ...element,
                              price: priceAllCoti,
                              symbol,
                            })
                          }
                        }
                      }
                    })
                  })
                })
              })
            })
          }

          this.cotizadorHotelsResultSelect.rooms.forEach(elementR => {
            elementR.days.forEach(elementDDD => {
              elementDDD.group_net.forEach(elementGroup => {
                if (elementGroup.room_id === this.ocupation.room_id
                  && elementGroup.plan_id === this.ocupation.plan
                  && elementGroup.code_tarifa === this.ocupation.codeTarifa) {
                  this.tiposCamas = elementGroup.tipo_cama
                  this.cupos = elementGroup.day_by_day[0].availableQuota
                  // eslint-disable-next-line no-plusplus
                  for (let jj = 1; jj < elementGroup.day_by_day.length; jj++) {
                    const elementDBD = elementGroup.day_by_day[jj]
                    if (elementDBD.availableQuota < this.cupos) {
                      this.cupos = elementDBD.availableQuota
                    }
                  }
                }
              })
            })
          })
        }

        // eslint-disable-next-line prefer-destructuring, vue/no-side-effects-in-computed-properties
        // this.ocupation.plan = this.regimenOcup[0].id
      }
    },
    initTarifaOcup() {
      this.tarifaOcup = []
      if (this.ocupation.room_id) {
        // eslint-disable-next-line no-unused-vars
        let priceRoomPlanTarifa = 0
        this.regimenPrices.forEach(element => {
          if (element.plan_id === this.ocupation.plan
          && element.room_id === this.ocupation.room_id
          && element.code_tarifa === this.ocupation.codeTarifa) {
            priceRoomPlanTarifa = element.priceAll
          }
        })

        this.ocupation.tarifasSeason.forEach(element => {
          let priceAllCoti = 0
          let symbol = '+'
          this.regimenPrices.forEach(elementPrice => {
            if (elementPrice.plan_id === this.ocupation.plan
            && elementPrice.room_id === this.ocupation.room_id
            && element.code === elementPrice.code_tarifa) {
              priceAllCoti = elementPrice.priceAll
              if (elementPrice.priceAll >= priceRoomPlanTarifa) {
                priceAllCoti = `+${((elementPrice.priceAll - priceRoomPlanTarifa)).toFixed(2)}`
              } else {
                symbol = '-'
                priceAllCoti = `-${((priceRoomPlanTarifa - elementPrice.priceAll)).toFixed(2)}`
              }
            }
          })
          this.tarifaOcup.push({
            ...element,
            price: priceAllCoti,
            symbol,
          })
        })
      }
    },
    regimenValues(regimen) {
      let priceRoomPlanTarifa = 0
      this.regimenPrices.forEach(element => {
        if (element.plan_id === this.ocupation.plan
        && element.room_id === this.ocupation.room_id
        && element.code_tarifa === this.ocupation.codeTarifa) {
          priceRoomPlanTarifa = element.priceAll
        }
      })
      let result = ''
      this.regimenPrices.forEach(element => {
        if (element.plan_id === regimen.id
        && element.room_id === this.ocupation.room_id
        && element.code_tarifa === this.ocupation.codeTarifa) {
          if (element.priceAll >= priceRoomPlanTarifa) {
            result = `+${((element.priceAll - priceRoomPlanTarifa) / this.cotizadorHotelsResultSelect.days_search).toFixed(2)}`
          } else {
            result = `-${((priceRoomPlanTarifa - element.priceAll) / this.cotizadorHotelsResultSelect.days_search).toFixed(2)}`
          }
        }
      })

      return result
    },
    penalidad(data) {
      let result = ''
      if (data.antes_de) {
        result += `${this.$t('lbl.antesDe')} ${data.from} ${data.param === 'days' ? this.$t('lbl.day') : data.param}, `
      } else {
        result += `${this.$t('lbl.entre')} ${data.from} ${this.$t('lbl.and')} ${data.to} ${data.param}, `
      }
      result += !data.penalidad_in_pc ? this.currencySymbolFacturar + data.penalidad_valor : data.penalidad_valor

      result += data.penalidad_in_pc ? ' %' : ''
      result += ` ${this.$t('lbl.of')}`
      if (data.all_rooms) {
        result += ` ${this.$t('lbl.roomsAll')}`
      } else {
        result += ` ${this.$tc('lbl.roomsCount', data.cant_rooms)}`
      }
      result += ` ${this.$t('lbl.for')}`
      if (data.all_nights) {
        result += ` ${this.$t('lbl.nightAll')}`
      } else {
        result += ` ${this.$tc('lbl.nightCount', data.cant_nigths)}`
      }

      return result
    },
    profile() {
      this.user = JSON.parse(localStorage.getItem('profile'))
      if (this.user.nivel === -1 || this.user.nivel === 0) {
        this.is_nivel_propietario = true
        this.infoImportant = true
      }
    },
    filterRooms(v) {
      this.itemsRooms = []
      if (v === '') {
        this.itemsRooms = []
      } else {
        const result = this.roomsHotel.filter(e => e.name.toLowerCase())
        result.forEach(element => {
          this.itemsRooms.push({
            id: element.id,
            name: element.name,
          })
        })
      }
    },
    initRoom() {
      if (this.cotizadorHotelsResultSelect.rooms.filter(e => e.room_id === this.ocupation.room_id).length > 0) {
        const habInit = this.cotizadorHotelsResultSelect.rooms.filter(e => e.room_id === this.ocupation.room_id)[0]
        const hhh = this.roomsHotel.filter(e => e.id === habInit.room_id)
        if (hhh.length > 0) {
          this.itemsRooms.push({
            id: hhh[0].id,
            name: hhh[0].name,
          })
        }
      }
    },
    nameRoomWithPrice(name, roomId) {
      let priceForRoom = 0
      this.cotizadorHotelsResultSelect.rooms.forEach(element => {
        element.days.forEach(elementDDD => {
          if (elementDDD.room_id === roomId && this.ocupation.plan === elementDDD.plan_id) {
            priceForRoom = elementDDD.priceAll
          }
        })
      })

      return `${name} ($${priceForRoom})`
    },
    async getRooms() {
      await this.axios
        .get('nom_habitacion_hotels?page=1&per_page=100000', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.roomsNomAll = res.data.data
            const ids = []

            this.cotizadorHotelsResultSelect.rooms.forEach(room => {
              //  && this.ocupation.plan
              if (this.ocupation.cantAdulEdad === room.cantAdulEdad
                  && this.ocupation.cantTeenEdad === room.cantTeenEdad
                  && this.ocupation.cantMnrEdad === room.cantMnrEdad
                  && this.ocupation.cantInfEdad === room.cantInfEdad
              ) {
                room.days.forEach(elementDDD => {
                  this.roomsNomAll.forEach(roomN => {
                    if (!ids.includes(roomN.id)) {
                      elementDDD.group_net.forEach(elementGroup => {
                        elementGroup.day_by_day.forEach(dayBy => {
                          // AQUI DESPUES HAY Q MODIFICAR ESTOOOOO
                          if (roomN.id === dayBy.room_id && dayBy.code_tarifa === this.cotizadorHotelsResultSelect.code_tarifa) {
                            if (!ids.includes(dayBy.room_id)) {
                              ids.push(roomN.id)
                              this.roomsHotel.push({
                                id: roomN.id,
                                name: roomN.name,
                              })
                            }
                          }
                        })
                      })
                    }
                  })
                })

                /* room.days.forEach(elementDDD => {
                  this.roomsNomAll.forEach(roomN => {
                    if (parseInt(elementDDD.room_id, 10) === roomN.id) {
                      if (!ids.includes(roomN.id)) {
                        ids.push(roomN.id)
                        this.roomsHotel.push({
                          id: roomN.id,
                          name: roomN.name,
                        })
                      }
                    }
                  })
                }) */
              }
            })
          }
        })

      // .finally(() => this.initRoom())
        .finally(() => {
          this.isLoading = false
        })
    },
    changeRoom() {
      let priceForRoom = 0
      let priceForRoomMark = 0
      this.setShowRoomHotelCotizador(!this.showOcupationRoomHotel)

      // ACTUALIZAR EL ROOM_ID
      this.updateOcupationHotelOnlyRoomId({
        pos: this.index,
        room_id: this.ocupation.room_id,
      })

      // BUSCAR EL PRECIO NETO X TARIFA/PLAN/HAB
      let prineNetoReview = 0
      const codeTarifaNet = this.cotizadorHotelsResultSelect.code_tarifa
      this.cotizadorHotelsResultSelect.rooms.forEach(element => {
        if (element.cantAdulEdad === this.ocupation.cantAdulEdad
          && element.cantTeenEdad === this.ocupation.cantTeenEdad
          && element.cantMnrEdad === this.ocupation.cantMnrEdad
          && element.cantInfEdad === this.ocupation.cantInfEdad
        ) {
          // const planIdNet = element.plan

          const planIdNet = this.ocupation.plan
          const roomIdNet = element.room_id
          element.days.forEach(elementDay => {
            elementDay.group_net.forEach(elementDayGroup => {
              if (codeTarifaNet === elementDayGroup.code_tarifa
              && planIdNet === elementDayGroup.plan_id
              && roomIdNet === elementDayGroup.room_id
              ) {
                prineNetoReview += elementDayGroup.price_net
              }
            })
          })
        }
      })

      this.updateOcupationHotelPriceNeto({
        pos: this.index,
        priceNeto: prineNetoReview,
      })

      priceForRoom = (prineNetoReview / this.tasaCambioContrate) * this.tasaCambioFacturar
      // eslint-disable-next-line no-unused-vars
      priceForRoomMark = priceForRoom

      // eslint-disable-next-line no-unused-vars
      let plan = ''
      // eslint-disable-next-line no-unused-vars
      let uso = ''

      // BUSCAR PLAN Y USO
      if (this.ocupation.room_id) {
        const codeTarifaSelect = this.cotizadorHotelsResultSelect.code_tarifa

        this.cotizadorHotelsResultSelect.rooms.forEach(element => {
          let encontrado = false
          element.days.forEach(elementDDD => {
            if (!encontrado) {
              elementDDD.group_net.forEach(elementGroup => {
                elementGroup.day_by_day.forEach(dayBy => {
                  const planId = this.regimenes.filter(e => e.siglas === dayBy.plan)[0].id
                  if (dayBy.room_id === this.ocupation.room_id
                    && this.ocupation.plan === planId
                    && this.ocupation.cantAdulEdad === dayBy.adult
                    && this.ocupation.cantTeenEdad === dayBy.teen
                    && this.ocupation.cantMnrEdad === dayBy.children
                    && this.ocupation.cantInfEdad === dayBy.babies
                    && codeTarifaSelect === dayBy.code_tarifa
                  ) {
                    // elementDDD.plan_id
                    plan = planId

                    // priceForRoom = parseFloat(dayBy.price) * this.cotizadorHotelsResultSelect.days_search
                    // priceForRoomMark = elementDDD.priceAllMark
                    if (dayBy.adult === 1) {
                      uso = 'sgl'
                    } else if (dayBy.adult === 2) {
                      uso = 'dbl'
                    } else if (dayBy.adult === 3) {
                      uso = 'tpl'
                    }

                    encontrado = true
                  }
                })
              })
            }
          })
        })
      }

      // RECALCULAR EL MARKUPS
      /* let valueMarkup = '%'
      if (!this.cotizadorHotelsSelectMarkups.valueMarkup === '$') {
        valueMarkup = '$'
      }
      let markup = 1
      if (this.cotizadorHotelsSelectMarkups.markup && valueMarkup === '%') {
        markup = this.cotizadorHotelsSelectMarkups.markup
      } else if (this.cotizadorHotelsSelectMarkups.markup && valueMarkup === '$') {
        markup = this.cotizadorHotelsSelectMarkups.markup * this.cotizadorHotelsResultSelect.days_search
      }

      let incluyeComition = 0
      let priceForRoomNew = 0
      if (markup) {
        if (valueMarkup === '%') {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties, no-mixed-operators
          incluyeComition = (parseFloat(priceForRoom) * parseFloat(markup)) / 100
          // eslint-disable-next-line no-mixed-operators
          priceForRoomNew = parseFloat(priceForRoom) + incluyeComition
        } else {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties, no-mixed-operators
          incluyeComition = parseFloat(markup)
          // eslint-disable-next-line no-unused-vars
          priceForRoomNew = parseFloat(priceForRoom) + incluyeComition
        }

        this.updateOcupationHotel({
          pos: this.index,
          paxs: this.ocupation.paxs,
          room_id: this.ocupation.room_id,
          plan,
          uso,
          priceNeto,
          priceTotal: priceForRoom,
          priceWithMarkups: priceForRoomMark,
          priceWithDescuento: priceForRoomMark,
          cantAdulEdad: this.ocupation.cantAdulEdad,
          cantTeenEdad: this.ocupation.cantTeenEdad,
          cantMnrEdad: this.ocupation.cantMnrEdad,
          cantInfEdad: this.ocupation.cantInfEdad,
        })
      } else {
        this.updateOcupationHotel({
          pos: this.index,
          paxs: this.ocupation.paxs,
          room_id: this.ocupation.room_id,
          plan,
          uso,
          priceNeto,
          priceTotal: priceForRoom,
          priceWithMarkups: priceForRoomMark,
          priceWithDescuento: priceForRoomMark,
          cantAdulEdad: this.ocupation.cantAdulEdad,
          cantTeenEdad: this.ocupation.cantTeenEdad,
          cantMnrEdad: this.ocupation.cantMnrEdad,
          cantInfEdad: this.ocupation.cantInfEdad,
        })
      } */

      // NUEVA VERSION
      const markup = localStorage.getItem('markup')
      const valueMarkup = localStorage.getItem('valueMarkup')
      const descuento = localStorage.getItem('descuento')
      const valueDescuento = localStorage.getItem('valueDescuento')

      const priceNetoNoEdit = this.cotizadorHotelsRomms[this.index].priceNeto
      const priceNetoNew = (priceNetoNoEdit / this.tasaCambioContrate) * this.tasaCambioFacturar
      const priceTotal = priceNetoNew

      let incluyeComition = 0
      let incluyeDescuento = 0
      let priceWithMarkups = 0
      let priceWithDescuento = 0
      if (markup) {
        // %
        if (valueMarkup === '%') {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties, no-mixed-operators
          incluyeComition = (parseFloat(priceTotal) * parseFloat(markup)) / 100
          // eslint-disable-next-line no-mixed-operators
          priceWithMarkups = parseFloat(priceTotal) + incluyeComition
        } // USD
        if (valueMarkup === this.currencyCodeFacturar) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties, no-mixed-operators
          incluyeComition = parseFloat(markup)
          priceWithMarkups = parseFloat(priceTotal) + incluyeComition
        }
      }

      if (descuento !== null && descuento !== 'null') {
        // %
        if (valueDescuento === '%') {
          // eslint-disable-next-line no-mixed-operators, vue/no-side-effects-in-computed-properties
          incluyeDescuento = (priceWithMarkups * parseFloat(descuento)) / 100

          // eslint-disable-next-line no-mixed-operators
          priceWithDescuento = parseFloat(priceWithMarkups) - incluyeDescuento
        // eslint-disable-next-line brace-style
        }

        // if (valueDescuento === this.currencyCodeFacturar) {
        else {
          // eslint-disable-next-line no-mixed-operators, vue/no-side-effects-in-computed-properties
          incluyeDescuento = parseFloat(descuento) / this.cotizadorHotelsRomms.length
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          priceWithDescuento = parseFloat(priceWithMarkups) - incluyeDescuento
        }
      } else {
        priceWithDescuento = priceWithMarkups
      }

      this.updateOcupationHotelPricesFacturar({
        pos: this.index,
        priceNeto: prineNetoReview,
        priceTotal,
        priceWithMarkups,
        priceWithDescuento,
      })

      let priceAll = 0
      this.cotizadorHotels.ocupation.forEach(ocupa => {
        priceAll += ocupa.priceWithDescuento
      })
      this.setCotizadorHotelSelectPriceTotal(priceAll)
      this.setCotizadorHotelsRomms()

      this.setShowRoomHotelCotizador(!this.showOcupationRoomHotel)

      // FALTA AQUI APLICAR EL MARKUPS
      this.$emit('applyMarkups')

      // RECALCULAR LOS PRICES DE LOS PLANES
      this.initRegimenOcup()
      this.initTarifaOcup()
    },
    setShowAllOcupation() {
      this.showAll = false

      // const json = { pos: this.index, value: !this.cotizadorHotels.ocupation[this.index].showAllPaxs }
      const json = { pos: this.index, value: this.showAllPaxsOcupation }
      this.setOcupationHotelShowAll(json)

      setTimeout(() => {
        this.showAll = true
      }, 50)
    },
    changePlan() {
      this.setShowRoomHotelCotizador(!this.showOcupationRoomHotel)

      this.updatePlanOcupationHotel({
        pos: this.index,
        room_id: this.ocupation.room_id,
        plan: this.ocupation.plan,
        codeTarifa: this.ocupation.codeTarifa,

        // priceTotal: priceForRoom,
        // priceWithMarkups: priceForRoom,
        // priceWithDescuento: priceForRoom,
      })

      // ACTUALIZAR PRECIOS DE LA HAB
      const codeTarifaSelect = this.cotizadorHotelsResultSelect.code_tarifa
      let priceNeto = 0
      this.cotizadorHotelsResultSelect.rooms.forEach(element => {
        let encontrado = false
        element.days.forEach(elementDDD => {
          if (!encontrado) {
            elementDDD.group_net.forEach(elementGroup => {
              elementGroup.day_by_day.forEach(dayBy => {
                const planId = this.regimenes.filter(e => e.siglas === dayBy.plan)[0].id
                if (dayBy.room_id === this.ocupation.room_id
                  && this.ocupation.plan === planId
                  && this.ocupation.cantAdulEdad === dayBy.adult
                  && this.ocupation.cantTeenEdad === dayBy.teen
                  && this.ocupation.cantMnrEdad === dayBy.children
                  && this.ocupation.cantInfEdad === dayBy.babies
                  && codeTarifaSelect === dayBy.code_tarifa
                ) {
                  priceNeto += dayBy.amount
                  encontrado = true
                }
              })
            })
          }
        })
      })

      // this.cotizadorHotelsRomms[this.index].priceNeto = priceNeto
      let posIntex = -1
      // eslint-disable-next-line no-plusplus
      for (let jjj = 0; jjj < this.cotizadorHotelsRomms.length; jjj++) {
        const element = this.cotizadorHotelsRomms[jjj]
        if (element.room_id === this.ocupation.room_id
              && this.ocupation.plan === element.plan_id
              && this.ocupation.cantAdulEdad === element.cantAdulEdad
              && this.ocupation.cantTeenEdad === element.cantTeenEdad
              && this.ocupation.cantMnrEdad === element.cantMnrEdad
              && this.ocupation.cantInfEdad === element.cantInfEdad
              && codeTarifaSelect === element.codeTarifa
        ) {
          posIntex = jjj
        }
      }
      if (posIntex > -1) {
        this.cotizadorHotelsRomms[posIntex].priceNeto = priceNeto
      }

      this.updateOcupationHotelPricesChangePlan({
        pos: this.index,
        priceNeto,
        plan: this.ocupation.plan,
        codeTarifa: this.ocupation.codeTarifa,

        // priceTotal: this.cotizadorHotelsRomms[this.index].priceTotal,
        // priceWithMarkups: this.cotizadorHotelsRomms[this.index].priceWithMarkups,
        // priceWithDescuento: this.cotizadorHotelsRomms[this.index].priceWithDescuento,
      })

      /* this.setCotizadorHotelsRomms() */

      setTimeout(() => {
        this.changeRoom()
        this.setShowRoomHotelCotizador(!this.showOcupationRoomHotel)
      }, 100)

      // RECALCULAR LOS PRICES DE LOS PLANES
      this.recalcularPricesPlanes()
    },
    recalcularPricesPlanes() {
      let priceRoomPlanTarifa = 0
      this.regimenPrices.forEach(element => {
        if (element.plan_id === this.ocupation.plan
        && element.room_id === this.ocupation.room_id
        && element.code_tarifa === this.ocupation.codeTarifa) {
          priceRoomPlanTarifa = element.priceAll
        }
      })
      this.regimenOcup.forEach(elementRO => {
        let { price } = elementRO
        let { symbol } = elementRO
        this.regimenPrices.forEach(element => {
          if (element.plan_id === elementRO.id
          && element.room_id === this.ocupation.room_id
          && element.code_tarifa === this.ocupation.codeTarifa) {
            if (element.priceAll >= priceRoomPlanTarifa) {
              symbol = '+'
              price = `+${((element.priceAll - priceRoomPlanTarifa) / this.cotizadorHotelsResultSelect.days_search).toFixed(2)}`
            } else {
              symbol = '-'
              price = `-${((priceRoomPlanTarifa - element.priceAll) / this.cotizadorHotelsResultSelect.days_search).toFixed(2)}`
            }
          }
        })

        // eslint-disable-next-line no-param-reassign
        elementRO.symbol = symbol
        // eslint-disable-next-line no-param-reassign
        elementRO.price = price
      })
    },

    changeTarifa() {
      this.setShowRoomHotelCotizador(!this.showOcupationRoomHotel)

      this.updatePlanOcupationHotel({
        pos: this.index,
        room_id: this.ocupation.room_id,
        plan: this.ocupation.plan,
        codeTarifa: this.ocupation.codeTarifa,
      })

      // ACTUALIZAR PRECIOS DE LA HAB
      const codeTarifaSelect = this.ocupation.codeTarifa
      let priceNeto = 0
      this.cotizadorHotelsResultSelect.rooms.forEach(element => {
        let encontrado = false
        element.days.forEach(elementDDD => {
          if (!encontrado) {
            elementDDD.group_net.forEach(elementGroup => {
              elementGroup.day_by_day.forEach(dayBy => {
                const planId = this.regimenes.filter(e => e.siglas === dayBy.plan)[0].id
                if (dayBy.room_id === this.ocupation.room_id
                  && this.ocupation.plan === planId
                  && this.ocupation.cantAdulEdad === dayBy.adult
                  && this.ocupation.cantTeenEdad === dayBy.teen
                  && this.ocupation.cantMnrEdad === dayBy.children
                  && this.ocupation.cantInfEdad === dayBy.babies
                  && codeTarifaSelect === dayBy.code_tarifa
                ) {
                  priceNeto += dayBy.amount
                  encontrado = true
                }
              })
            })
          }
        })
      })

      // this.cotizadorHotelsRomms[this.index].priceNeto = priceNeto
      let posIntex = -1
      // eslint-disable-next-line no-plusplus
      for (let jjj = 0; jjj < this.cotizadorHotelsRomms.length; jjj++) {
        const element = this.cotizadorHotelsRomms[jjj]
        if (element.room_id === this.ocupation.room_id
              && this.ocupation.plan === element.plan_id
              && this.ocupation.cantAdulEdad === element.cantAdulEdad
              && this.ocupation.cantTeenEdad === element.cantTeenEdad
              && this.ocupation.cantMnrEdad === element.cantMnrEdad
              && this.ocupation.cantInfEdad === element.cantInfEdad
              && codeTarifaSelect === element.codeTarifa
        ) {
          posIntex = jjj
        }
      }
      if (posIntex > -1) {
        this.cotizadorHotelsRomms[posIntex].priceNeto = priceNeto
      }

      this.updateOcupationHotelPricesChangePlan({
        pos: this.index,
        priceNeto,
        plan: this.ocupation.plan,
        codeTarifa: this.ocupation.codeTarifa,
      })

      setTimeout(() => {
        this.changeRoom()
        this.setShowRoomHotelCotizador(!this.showOcupationRoomHotel)
      }, 100)
    },

    showModal() {
      this.dialog = true
    },
    addObservation() {
      this.dialogObservation = true
    },
    addNewObservation() {
      this.addOcupationObsevations({
        ind: this.index,
        observation: this.observation,
      })
      this.dialogObservation = false
      this.observation = {
        observation: null,
        proveedor: false,
        client: false,
      }
      this.checkAllService = false
    },
    addNewObservationAll() {
      this.addOcupationObsevationsAllRooms({
        observation: this.observation,
      })
      this.dialogObservation = false
      this.observation = {
        observation: null,
        proveedor: false,
        client: false,
      }
      this.checkAllService = false
      this.$emit('resetRooms')
    },
    editObservation(pos, obs) {
      this.posObservationDelete = pos
      this.observation = obs
      this.dialogObservationEdit = true
    },
    editNewObservation() {
      this.updateOcupationObsevations({
        ind: this.index,
        observations: this.ocupation.observations,
      })
      this.dialogObservationEdit = false
      this.observation = {
        observation: null,
        proveedor: false,
        client: false,
      }
      this.checkAllService = false
    },
    deleteObservation(pos, obs) {
      this.posObservationDelete = pos
      this.observation = obs
      this.dialogObservationDelete = true
    },
    confirmDeleteObs() {
      const json = { ind: this.index, pos: this.posObservationDelete }
      this.deleteOcupationObsevations(json)
      this.dialogObservationDelete = false
      this.observation = {
        observation: null,
        proveedor: false,
        client: false,
      }
      this.checkAllService = false
    },
  },
}
</script>

<style>
.topMine {
  top: 70px !important;
}

@keyframes example {
  from {
    top: auto;
  }

  to {
    top: 70px;
  }
}

.topMine {
  animation-name: example;
  transition-duration: 2s;
  animation-duration: 1s;
  scroll-behavior: smooth;
}

#titleDispVueloMine {
  background-color: #2d3137;
  color: white;
  padding: 10px 12px;
}

#titleDispVueloMine > div > p {
  color: white !important;
  font-size: 14px;
}

#titleDispVueloMine > div > h5 {
  color: white !important;
}

#fromToHeadMine p {
  font-size: 14px;
}

#expandDispMine p {
  font-size: 12px;
  padding: 5px 0px;
}

.v-timeline:before {
  bottom: 0;
  content: '';
  height: 25%;
  position: absolute;
  top: 25px !important;
  width: 2px;
}

#timeLineMovilMine.v-timeline:before {
  bottom: 0;
  content: '';
  height: 35%;
  position: absolute;
  top: 25px !important;
  width: 2px;
}

.p14Mine {
  font-size: 14px !important;
}

.p12Mine {
  font-size: 12px !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div
  > div
  > div.col-md-8.col-12
  > div:nth-child(3)
  > div
  > div.container.grid-list-xs
  > div
  > div:nth-child(6)
  > div {
  margin-top: 5px;
}

::v-deep .descuento .v-label {
  font-size: 14px !important;
}
</style>
